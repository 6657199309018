module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136010320-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Umair Ahmed - Product Developer Portfolio","short_name":"Umair's Portfolio","description":"Umair Ahmed - A product developer portfolio website. Web developer working in a consultancy building software","start_url":"/","background_color":"#a0d8f1","theme_color":"#e07628","display":"standalone","icon":"static/logo.png","icons":[{"src":"/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/favicons/android-icon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"fc9d84958ae24d252c59c1c04fe3f8bd"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
